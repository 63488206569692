import React from "react";
import EditRoomContent from "./RoomEdit";

function EditRoom() {
  return (
    <>
      <EditRoomContent />
    </>
  );
}

export default EditRoom;
